#intro {
  height: calc(100vh - 4rem);
  width: 100vw;
  max-width: 75rem;
  margin: 0 auto;
  margin-bottom: 50px;
  overflow: hidden;
  position: relative;
}

.bg {
  position: absolute;
  top: 5rem;
  right: 0;
  z-index: -1;
  object-fit: cover;
  height: 100vh;
}

.introOverlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(30, 30, 30, 0.6);
  z-index: 0;
}

.introContent {
  padding: 2rem;
  font-size: 3rem;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 1; 
  color: lightgrey;
  max-width: 60%;
  text-shadow: 0 0 10px rgb(30, 30, 30);
}

.hello {
  font-size: 1.75rem;
  font-weight: 200;
}

.introName {
  color: yellow;
}

.introPara {
  font-size: large;
  font-weight: 300;
  letter-spacing: 1px;
}

.btn {
  background: white;
  color: black;
  margin: 1rem 0;
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 2rem;
  cursor: pointer;
}

.btn:hover {
  background: darkgray;
}

.btnImg {
  object-fit: cover;
  margin: 0 0.25rem;
  height: 1rem;
}

@media screen and (max-width: 840px){
  .bg {
    right: -10vw;
  }
  .introContent {
    font-size: 10vw;
    max-width: 100%;
  }
  .hello {
    font-size: 4.5vw;
  }
}

@media screen and (max-width: 480px){
  .bg {
    right: -80vw;
  }
  .introContent {
    font-size: 6vw;
    max-width: 100%;
  }
}
