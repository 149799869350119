#works {
  margin: 0 auto;
  margin-bottom: 50px;
  min-height: calc(100vh - 4rem);
  width: 100vw;
  max-width: 60rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 1rem;
}

.workContainer {
  position: relative;
  width: 300px; 
  height: 200px;
  margin: 0.5rem;
  overflow: hidden;
}

.imgWrapper {
  width: 100%;
  height: 100%;
}

.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.projectName, .comingSoon, .projectLink, .wipLabel {
  color: white;
  font-size: 1.2rem;
  padding: 0.5rem;
  text-align: center;
}

.comingSoon {
  color: red;
}

.projectLink {
  color: #63a0ef; 
  text-decoration: underline; 
  font-weight: normal; 
}

.projectLink:hover {
  color: #1e6cd8; 
  text-decoration: none;
}

.wipLabel {
  background-color: #ffcc00; 
  font-weight: bold;
  font-size: 1rem;
  margin-bottom: 0.5rem;
  padding: 0.3rem 0.5rem;
  border-radius: 0.5rem;
}

.workContainer:hover .overlay {
  opacity: 1;
}

.worksTitle {
  margin: 1rem 0;
  font-size: 3rem;
}

.worksDesc {
  font-weight: 300;
  font-size: 1rem;
  max-width: 45rem;
}

.worksImgs {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100vw;
  max-width: 65rem;
}

.worksImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.workBtn {
  margin: 3rem 0;
  padding: 0.5rem 2.5rem;
  border: none;
  border-radius: 2rem;
  background: white;
  color: black;
  font-size: 1rem;
}

.workBtn:hover {
  background: darkgray;
}

@media screen and (max-width: 585px) {
  .workContainer {
    width: 90vw;
    height: 60vw; 
  }
}
